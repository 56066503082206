// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try 
    {
      //attempt to get requested data
      //  supply
      let totalSupply = await store.getState().blockchain.smartContract.methods.totalSupply().call();
      //  cost
      // let cost = await store.getState().blockchain.smartContract.methods.cost().call();
      
      let nftSupply0 = await store.getState().blockchain.smartContracts[0].methods.totalSupply().call();
      let nftSupply1 = await store.getState().blockchain.smartContracts[1].methods.totalSupply().call();
      let nftSupply2 = await store.getState().blockchain.smartContracts[2].methods.totalSupply().call();
      let nftSupply3 = await store.getState().blockchain.smartContracts[3].methods.totalSupply().call();
     
      
      //return supply
      dispatch( fetchDataSuccess({ totalSupply:totalSupply, totalSupply0:nftSupply0, totalSupply1:nftSupply1, totalSupply2:nftSupply2, totalSupply3:nftSupply3, }) );// cost, }) );
    } 
    catch (err) 
    {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
