//blockchain data capsule
const initialState = 
{
  loading: false,
  
  account: null,
  smartContract: null,
  smartContracts: [null, null, null, null],
  
  web3: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        
        //account details
        account: action.payload.account,
        
        //contract details
        smartContract: action.payload.smartContract,
        smartContracts: [action.payload.smartContract0, action.payload.smartContract1, action.payload.smartContract2, action.payload.smartContract3],
        
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
