import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active 
  {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active 
  {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) 
  {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) 
  {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 300px;
  height: 260px;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState(
  {
    SELECTED_NFT: 0,
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: { NAME: "", SYMBOL: "", ID: 0, },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    
    HOSTED_NFTS: 
    [
      {  CONTRACT:"", SCAN:"", MARKETPLACE:"", NAME:"", SYMBOL:"", RACE:"", PREVIEW:"", DESC:"" },
      {  CONTRACT:"", SCAN:"", MARKETPLACE:"", NAME:"", SYMBOL:"", RACE:"", PREVIEW:"", DESC:"" },
      {  CONTRACT:"", SCAN:"", MARKETPLACE:"", NAME:"", SYMBOL:"", RACE:"", PREVIEW:"", DESC:"" },
      {  CONTRACT:"", SCAN:"", MARKETPLACE:"", NAME:"", SYMBOL:"", RACE:"", PREVIEW:"", DESC:"" }
    ]
  });

  const claimNFTs = (index) => 
  {
    console.log("attempting to claim NFT: ", index);
    
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmount);
    console.log("NFT cost: ", totalCostWei);
    
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("NFT transaction gas limit: ", totalGasLimit);
    
    setFeedback(`Minting your ${CONFIG.HOSTED_NFTS[index].NAME}...`);
    
    setClaimingNft(true);
    blockchain.smartContracts[index].methods
      .mint(blockchain.account, mintAmount)
      .send({ gasLimit: String(totalGasLimit), to: CONFIG.HOSTED_NFTS[index].CONTRACT, from: blockchain.account, value: totalCostWei, })
      .once("error", (err) => 
      {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => 
      {
        console.log(receipt);
        setFeedback( `WOW, the ${CONFIG.HOSTED_NFTS[index].NAME} is yours! go visit Opensea.io to view it.` );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => 
  {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) 
    {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => 
  {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) 
    {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => 
  {
    if (blockchain.account !== "" && blockchain.smartContract !== null) 
    {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => 
  {
    const configResponse = await fetch("/config/config.json", { headers: { "Content-Type": "application/json", Accept: "application/json", }, });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => { getConfig(); }, []);

  useEffect(() => { getData(); }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container flex={1} ai={"center"} style={{ padding: 24, backgroundColor: "var(--primary)" }} image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}  >
     
        <s.Container flex={1} jc={"center"} ai={"center"}> <StyledImg alt={"example"} src={"/config/images/icon.png"} /> </s.Container>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          
          <s.Container flex={2} jc={"center"} ai={"center"}
            style={{ backgroundColor: "var(--accent)", padding: 24, borderRadius: 24, border: "4px solid var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)", }}
          >
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}>About Decentrealm Characters</s.TextTitle>
            <s.SpacerSmall />
 
            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} >Fight to restore the realm, craft a better future, and make some incredible profits while you’re at it! Everything in Decentrealm (from characters and equipment to the land itself) is designed to be integrated onto the blockchain. This allows users to truly own their assets and have faith in the security of their holdings. Our foremost concerns while developing Decentrealm are fun, profit, and inclusivity: we seek to create a game world that is fully accessible to all users, a platform that caters to renters, and entertains users to no end! Defend cities side-by-side with your friends, defeat the forces of evil to claim sick NFT loot, and create meaningful value by building and crafting in player-owned estates!</s.TextDescription>
            <s.SpacerSmall />
              
            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>All of Decentrealm's NFT collections are hosted via the Polygon Network (the real network of the blockchain). While this may be a less popular solution it comes with some massive benefits to our development team and community! We'll have a massive leg-up on any competitors not using this technology due to the faster transaction speeds, cheaper processing, and significantly simplified domain management! We will even be building tools and algorithms to further enhance our integration of the Polygon Network.</s.TextDescription>            
            <s.SpacerSmall />
              
            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>Decentrealm Character NFTs provide you with a single boxed character that can be unveiled to display their stats, traits, and professions. Throughout development, current owners will also be airdropped additional goodies (multiple equipment pieces and land parcels)! </s.TextDescription>            
            <s.SpacerSmall />
              
            <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }}>Want more information on Decentrealm? We have a public-facing documentation page that outlines most aspects of the Decentrealm projectl, including our plans for equipment and land.</s.TextDescription>            
            <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
              <StyledLink target={"_blank"} href={"https://app.gitbook.com/s/n7cwuK1U6L4h9nseI8Nx/"}> Public Documentation </StyledLink>
            </s.TextDescription>

            <s.SpacerXSmall />
          </s.Container>
          <s.SpacerLarge />
        </ResponsiveWrapper>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          
          <s.Container flex={2} jc={"center"} ai={"center"}
            style={{ backgroundColor: "var(--accent)", padding: 24, borderRadius: 24, border: "4px solid var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)", }}
          >
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> Race: {CONFIG.HOSTED_NFTS[0].RACE} </s.TextTitle>
            <s.SpacerSmall />
            
            <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
              <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[0].SCAN}> {truncate(CONFIG.HOSTED_NFTS[0].CONTRACT, 15)} </StyledLink>
            </s.TextDescription>
                                                                      
            <s.Container flex={1} jc={"center"} ai={"center"}> <StyledImg alt={"example"} src={CONFIG.HOSTED_NFTS[0].PREVIEW} /> </s.Container>
            <s.SpacerSmall />
            
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> {data.totalSupplies[0]} / {CONFIG.MAX_SUPPLY} </s.TextTitle>
            <s.SpacerSmall />
                
            {Number(data.totalSupplies[0]) >= CONFIG.MAX_SUPPLY ? 
            (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }} > Sorry! The minting sale is over! </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > You can still view available characters on </s.TextDescription>
                <s.SpacerSmall /> <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[0].MARKETPLACE}> {CONFIG.MARKETPLACE} </StyledLink>
              </>
            ): 
            (
              <>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > {CONFIG.HOSTED_NFTS[0].DESC} </s.TextDescription>
                <s.SpacerSmall />
              
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}> 1 {CONFIG.HOSTED_NFTS[0].SYMBOL} costs {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}. </s.TextTitle>
                <s.SpacerXSmall />
                  
                {blockchain.account === "" || blockchain.smartContract === null ? 
                (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > Connect to the {CONFIG.NETWORK.NAME} network </s.TextDescription>
                    <s.SpacerSmall />
                      
                  <span style={{ textAlign: "center", }}>
                    <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }} > CONNECT </StyledButton>
                    <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[0].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                  </span>

                    {blockchain.errorMsg !== "" ? 
                    (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{  textAlign: "center", color: "var(--accent-text)", }} > {blockchain.errorMsg} </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ):
                (
                  <>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {feedback} </s.TextDescription>
                    <s.SpacerMedium />
                  
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); decrementMintAmount(); }} > - </StyledRoundButton>
                      <s.SpacerMedium />
                        
                      <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {mintAmount} </s.TextDescription>
                      <s.SpacerMedium />
                        
                      <StyledRoundButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); incrementMintAmount(); }} > + </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                        
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(0); getData(); }} > {claimingNft ? "BUSY" : "BUY"} </StyledButton>
                      <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[0].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
              
          <s.Container flex={2} jc={"center"} ai={"center"}
            style={{ backgroundColor: "var(--accent)", padding: 24, borderRadius: 24, border: "4px solid var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)", }}
          >
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> Race: {CONFIG.HOSTED_NFTS[1].RACE} </s.TextTitle>
            <s.SpacerSmall />
            
            <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
              <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[1].SCAN}> {truncate(CONFIG.HOSTED_NFTS[1].CONTRACT, 15)} </StyledLink>
            </s.TextDescription>
                                                                      
            <s.Container flex={1} jc={"center"} ai={"center"}> <StyledImg alt={"example"} src={CONFIG.HOSTED_NFTS[1].PREVIEW} /> </s.Container>
            <s.SpacerSmall />
            
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> {data.totalSupplies[1]} / {CONFIG.MAX_SUPPLY} </s.TextTitle>
            <s.SpacerSmall />
                
            {Number(data.totalSupplies[1]) >= CONFIG.MAX_SUPPLY ? 
            (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }} > Sorry! The minting sale is over! </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > You can still view available characters on </s.TextDescription>
                <s.SpacerSmall /> <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[1].MARKETPLACE}> {CONFIG.MARKETPLACE} </StyledLink>
              </>
            ): 
            (
              <>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > {CONFIG.HOSTED_NFTS[1].DESC} </s.TextDescription>
                <s.SpacerSmall />
              
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}> 1 {CONFIG.HOSTED_NFTS[1].SYMBOL} costs {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}. </s.TextTitle>
                <s.SpacerXSmall />
                  
                {blockchain.account === "" || blockchain.smartContract === null ? 
                (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > Connect to the {CONFIG.NETWORK.NAME} network </s.TextDescription>
                    <s.SpacerSmall />
                      
                  <span style={{ textAlign: "center", }}>
                    <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }} > CONNECT </StyledButton>
                    <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[1].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                  </span>

                    {blockchain.errorMsg !== "" ? 
                    (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{  textAlign: "center", color: "var(--accent-text)", }} > {blockchain.errorMsg} </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ):
                (
                  <>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {feedback} </s.TextDescription>
                    <s.SpacerMedium />
                  
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); decrementMintAmount(); }} > - </StyledRoundButton>
                      <s.SpacerMedium />
                        
                      <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {mintAmount} </s.TextDescription>
                      <s.SpacerMedium />
                        
                      <StyledRoundButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); incrementMintAmount(); }} > + </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                        
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(1); getData(); }} > {claimingNft ? "BUSY" : "BUY"} </StyledButton>
                      <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[1].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
             
        </ResponsiveWrapper>
        <s.SpacerMedium />
        
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          
          <s.Container flex={2} jc={"center"} ai={"center"}
            style={{ backgroundColor: "var(--accent)", padding: 24, borderRadius: 24, border: "4px solid var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)", }}
          >
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> Race: {CONFIG.HOSTED_NFTS[2].RACE} </s.TextTitle>
            <s.SpacerSmall />
            
            <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
              <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[2].SCAN}> {truncate(CONFIG.HOSTED_NFTS[2].CONTRACT, 15)} </StyledLink>
            </s.TextDescription>
                                                                      
            <s.Container flex={1} jc={"center"} ai={"center"}> <StyledImg alt={"example"} src={CONFIG.HOSTED_NFTS[2].PREVIEW} /> </s.Container>
            <s.SpacerSmall />
            
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> {data.totalSupplies[2]} / {CONFIG.MAX_SUPPLY} </s.TextTitle>
            <s.SpacerSmall />
                
            {Number(data.totalSupplies[2]) >= CONFIG.MAX_SUPPLY ? 
            (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }} > Sorry! The minting sale is over! </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > You can still view available characters on </s.TextDescription>
                <s.SpacerSmall /> <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[2].MARKETPLACE}> {CONFIG.MARKETPLACE} </StyledLink>
              </>
            ): 
            (
              <>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > {CONFIG.HOSTED_NFTS[2].DESC} </s.TextDescription>
                <s.SpacerSmall />
              
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}> 1 {CONFIG.HOSTED_NFTS[2].SYMBOL} costs {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}. </s.TextTitle>
                <s.SpacerXSmall />
                  
                {blockchain.account === "" || blockchain.smartContract === null ? 
                (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > Connect to the {CONFIG.NETWORK.NAME} network </s.TextDescription>
                    <s.SpacerSmall />
                      
                  <span style={{ textAlign: "center", }}>
                    <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }} > CONNECT </StyledButton>
                    <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[2].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                  </span>

                    {blockchain.errorMsg !== "" ? 
                    (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{  textAlign: "center", color: "var(--accent-text)", }} > {blockchain.errorMsg} </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ):
                (
                  <>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {feedback} </s.TextDescription>
                    <s.SpacerMedium />
                  
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); decrementMintAmount(); }} > - </StyledRoundButton>
                      <s.SpacerMedium />
                        
                      <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {mintAmount} </s.TextDescription>
                      <s.SpacerMedium />
                        
                      <StyledRoundButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); incrementMintAmount(); }} > + </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                        
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(2); getData(); }} > {claimingNft ? "BUSY" : "BUY"} </StyledButton>
                      <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[2].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
              
          <s.Container flex={2} jc={"center"} ai={"center"}
            style={{ backgroundColor: "var(--accent)", padding: 24, borderRadius: 24, border: "4px solid var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)", }}
          >
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> Race: {CONFIG.HOSTED_NFTS[3].RACE} </s.TextTitle>
            <s.SpacerSmall />
            
            <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }}>
              <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[3].SCAN}> {truncate(CONFIG.HOSTED_NFTS[3].CONTRACT, 15)} </StyledLink>
            </s.TextDescription>
                                                                      
            <s.Container flex={1} jc={"center"} ai={"center"}> <StyledImg alt={"example"} src={CONFIG.HOSTED_NFTS[3].PREVIEW} /> </s.Container>
            <s.SpacerSmall />
            
            <s.TextTitle style={{ textAlign: "center", fontSize: 50, fontWeight: "bold", color: "var(--accent-text)", }}> {data.totalSupplies[3]} / {CONFIG.MAX_SUPPLY} </s.TextTitle>
            <s.SpacerSmall />
                
            {Number(data.totalSupplies[3]) >= CONFIG.MAX_SUPPLY ? 
            (
              <>
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }} > Sorry! The minting sale is over! </s.TextTitle>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > You can still view available characters on </s.TextDescription>
                <s.SpacerSmall /> <StyledLink target={"_blank"} href={CONFIG.HOSTED_NFTS[3].MARKETPLACE}> {CONFIG.MARKETPLACE} </StyledLink>
              </>
            ): 
            (
              <>
                <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)" }} > {CONFIG.HOSTED_NFTS[3].DESC} </s.TextDescription>
                <s.SpacerSmall />
              
                <s.TextTitle style={{ textAlign: "center", color: "var(--accent-text)" }}> 1 {CONFIG.HOSTED_NFTS[3].SYMBOL} costs {CONFIG.DISPLAY_COST}{" "} {CONFIG.NETWORK.SYMBOL}. </s.TextTitle>
                <s.SpacerXSmall />
                  
                {blockchain.account === "" || blockchain.smartContract === null ? 
                (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > Connect to the {CONFIG.NETWORK.NAME} network </s.TextDescription>
                    <s.SpacerSmall />
                      
                  <span style={{ textAlign: "center", }}>
                    <StyledButton onClick={(e) => { e.preventDefault(); dispatch(connect()); getData(); }} > CONNECT </StyledButton>
                    <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[3].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                  </span>

                    {blockchain.errorMsg !== "" ? 
                    (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{  textAlign: "center", color: "var(--accent-text)", }} > {blockchain.errorMsg} </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ):
                (
                  <>
                    <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {feedback} </s.TextDescription>
                    <s.SpacerMedium />
                  
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton style={{ lineHeight: 0.4 }} disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); decrementMintAmount(); }} > - </StyledRoundButton>
                      <s.SpacerMedium />
                        
                      <s.TextDescription style={{ textAlign: "center", color: "var(--accent-text)", }} > {mintAmount} </s.TextDescription>
                      <s.SpacerMedium />
                        
                      <StyledRoundButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); incrementMintAmount(); }} > + </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                        
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton disabled={claimingNft ? 1 : 0} onClick={(e) => { e.preventDefault(); claimNFTs(3); getData(); }} > {claimingNft ? "BUSY" : "BUY"} </StyledButton>
                      <StyledButton style={{  margin: "5px", }} onClick={(e) => { window.open(CONFIG.HOSTED_NFTS[3].MARKETPLACE, "_blank"); }} > {CONFIG.MARKETPLACE} </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
             
        </ResponsiveWrapper>
        <s.SpacerMedium />
              
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }} >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
              
          <s.TextDescription style={{ textAlign: "center", color: "var(--primary-text)", }} >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
