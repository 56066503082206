//standard data capsule
const initialState = 
{
  loading: false,
  
  totalSupply: 0,
  totalSupplies: [0,0,0,0],
  
  cost: 0,
  
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        
        //record payload response
        totalSupply: action.payload.totalSupply,
        totalSupplies: [action.payload.totalSupply0, action.payload.totalSupply1, action.payload.totalSupply2, action.payload.totalSupply3],
        
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
