// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    
    //attempt connection
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", { headers: { "Content-Type": "application/json", Accept: "application/json", }, });
    
    //get abi
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", { headers: { "Content-Type": "application/json", Accept: "application/json", }, });
    
    //get config
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) 
    {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try 
      {
        //get account
        const accounts = await ethereum.request({ method: "eth_requestAccounts", });
        //get network
        const networkId = await ethereum.request({ method: "net_version", });
        
        //ensure current network is the targeted network
        if (networkId == CONFIG.NETWORK.ID) 
        {
          //get smart contract as single object (old)
          const SmartContractObj = new Web3EthContract( abi, CONFIG.CONTRACT_ADDRESS );
          
          //get smart contracts as array
          const SmartContractObj0 = new Web3EthContract( abi, CONFIG.HOSTED_NFTS[0].CONTRACT );
          const SmartContractObj1 = new Web3EthContract( abi, CONFIG.HOSTED_NFTS[1].CONTRACT );
          const SmartContractObj2 = new Web3EthContract( abi, CONFIG.HOSTED_NFTS[2].CONTRACT );
          const SmartContractObj3 = new Web3EthContract( abi, CONFIG.HOSTED_NFTS[3].CONTRACT );
          
          //return object
          dispatch( connectSuccess({ account:accounts[0], smartContract:SmartContractObj, smartContract0:SmartContractObj0, smartContract1:SmartContractObj1, smartContract2:SmartContractObj2, smartContract3:SmartContractObj3, web3: web3, }) );
          
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => { dispatch(updateAccount(accounts[0])); });
          ethereum.on("chainChanged", () => { window.location.reload(); });
          
          // Add listeners end
        } 
        else { dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`)); }
      } 
      catch (err) { dispatch(connectFailed("Something went wrong.")); }
    } 
    else { dispatch(connectFailed("Install Metamask.")); }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
